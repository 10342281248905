/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Authorization Scopes for users
 */
export enum AuthScope {
    ADMIN = 'admin',
    PARTY_ANIMAL = 'party_animal',
    PREGAMER = 'pregamer',
    AFTER_PARTY = 'after_party',
    THIRD_PARTY = 'third_party',
}