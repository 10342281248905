/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_login_for_access_token_api_v1_auth_login_post } from '../models/Body_login_for_access_token_api_v1_auth_login_post';
import type { PasscodeRedemptionRequest } from '../models/PasscodeRedemptionRequest';
import type { Token } from '../models/Token';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import https from 'https';

const Agent = new https.Agent({
  rejectUnauthorized: false,
});

export class AuthenticationAuthorizationService {
  /**
   * Login For Access Token
   * Log in using the provided username and password.
   * @param formData
   * @returns Token Successful Response
   * @throws ApiError
   */
  public static loginForAccessTokenApiV1AuthLoginPost(
    formData: Body_login_for_access_token_api_v1_auth_login_post
  ): CancelablePromise<Token> {
    return __request({
      method: 'POST',
      path: `/api/v1/auth/login`,
      formData: formData,
      httpsAgent: Agent,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Redeem Passcode
   * redeem a passcode and receive an auth token that allows asking for a signature.
   * @param requestBody
   * @returns Token Successful Response
   * @throws ApiError
   */
  public static redeemPasscodeApiV1AuthRedeemPost(
    requestBody: PasscodeRedemptionRequest
  ): CancelablePromise<Token> {
    return __request({
      method: 'POST',
      path: `/api/v1/auth/redeem`,
      body: requestBody,
      httpsAgent: Agent,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
