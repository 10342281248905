/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Passcode } from '../models/Passcode';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AdminFunctionsService {

    /**
     * Seed Passcodes
     * Seed passcodes into the database
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static seedPasscodesApiV1AdminSeedPasscodesPost(
        requestBody: Array<Passcode>,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/v1/admin/seed-passcodes`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get A Passcode
     * Seed passcodes into the database
     * @param data
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAPasscodeApiV1AdminPasscodeGet(
        data: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/v1/admin/passcode`,
            query: {
                'data': data,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}