/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SignatureRequest } from '../models/SignatureRequest';
import type { SignedMessage } from '../models/SignedMessage';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PartyFunctionsService {

    /**
     * Request Preparty Signature
     * Request a signed message.
     * @param requestBody
     * @returns SignedMessage Successful Response
     * @throws ApiError
     */
    public static requestPrepartySignatureApiV1PartySignaturePost(
        requestBody: SignatureRequest,
    ): CancelablePromise<SignedMessage> {
        return __request({
            method: 'POST',
            path: `/api/v1/party/signature`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}