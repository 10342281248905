import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import media from '../styles/media';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import One from '../sections/one';
import Two from '../sections/two';
import Three from '../sections/three';
import Four from '../sections/four';
import Five from '../sections/five';
import Six from '../sections/six';
import Seven from '../sections/seven';
import Eight from '../sections/eight';
import createPersistedState from 'use-persisted-state';
import Cookies from 'universal-cookie';
import crumplebgMobile from '../assets/home/background-mobile.jpg';
import crumplebgDesktop from '../assets/home/background-desktop2.jpg';
import brandHeroLogo from '../assets/home/logo-v2.svg';
import Modal from '../components/modal';
import {
  AuthenticationAuthorizationService,
  OpenAPI,
  PartyFunctionsService,
  //PasscodeRedemptionRequest,
  SignedMessage,
} from '../api-client';
import {
  AUTHENTICATED_COOKIE_KEY_2,
  PARTY_ANIMALZ_ETH_ACCOUNT,
  CONTRACT_ADDRESS_MAINNET,
  CONTRACT_ADDRESS_RINKEBY,
  MINT_PRICE,
} from '../lib/utils/consts';
import { providers } from 'ethers';
import { abi } from '../lib/abi/PASS.abi.js';
import { Contract } from '@ethersproject/contracts';
import { useStore } from '../lib/state/state';

declare var window: any;

const IndexPage = ({ location }: { location: any; data: any }) => {
  const modal: any = useRef(null);
  const cookies: any = new Cookies();
  const params: any = new URLSearchParams(location.search);
  const ethcookie: string = cookies.get(PARTY_ANIMALZ_ETH_ACCOUNT);
  const user: any = useStore((state: any) => state.user);
  const library: any = useStore((state: any) => state.library);

  const persistAuthenticated: any = createPersistedState(AUTHENTICATED_COOKIE_KEY_2);
  const [authenticated, setAuthenticated]: [boolean, Function] = persistAuthenticated(false);
  const [auth, setAuth]: [boolean, Function] = useState(false);
  const [email, setEmail]: [string, Function] = useState('');
  const [refcode, setRefcode]: [string, Function] = useState('');
  const [authToken, setAuthToken]: [string, Function] = useState('');
  const [modaltext, setModaltext]: [string, Function] = useState('Default text');
  const [ethCookie, setEthCookie]: [string, Function] = useState(ethcookie);
  const [alreadyMinted, setAlreadyMinted]: [boolean, Function] = useState(false);
  const [submitClicked, setSubmitClicked]: [boolean, Function] = useState(false);

  const handleModal = async (text: string) => {
    await setModaltext(text);
    modal.current.open();
  };

  const handleSetRefcode = async (e: any) => {
    e.preventDefault();
    if (refcode.length === 0 || email.length === 0) {
      return;
    }
    let ref: string = refcode.toUpperCase();

    try {
      let token = await AuthenticationAuthorizationService.redeemPasscodeApiV1AuthRedeemPost({
        email: email,
        passcode: ref,
      });
      if (token.access_token.length > 0) {
        OpenAPI.TOKEN = token.access_token;
        await setAuthToken(token.access_token);
        await setSubmitClicked(true);
        return;
      }
    } catch (error) {
      console.log(error);
    }
    handleModal('Either the referral code is invalid or it has been used too many times!');
  };

  const handleMint = async (e: any) => {
    e.preventDefault();
    setEthCookie(cookies.get(PARTY_ANIMALZ_ETH_ACCOUNT));
    if (!ethCookie) {
      //activateBrowserWallet()
      handleModal('wallet not connected. please connect your wallet.');
      return;
    }

    try {
      // configure the provider and contract instance
      const provider = new providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();

      console.log(`sign: ${address}`);

      const signatureResponse: SignedMessage =
        await PartyFunctionsService.requestPrepartySignatureApiV1PartySignaturePost({
          address: address,
        });

      const chainId = await window.ethereum.request({ method: 'eth_chainId' });

      console.log('signature response', signatureResponse);
      console.log(`chainId: ${chainId}`);

      // call the contract function.
      let contractAddress = CONTRACT_ADDRESS_MAINNET; //mainnet
      if (chainId === 0x04) {
        contractAddress = CONTRACT_ADDRESS_RINKEBY; //rinkeby
      }

      console.log(contractAddress);
      console.log('abi', abi);

      const instance = new Contract(contractAddress, abi, signer);
      const price = await instance.price();

      // send the transaction
      const tx = await instance.purchasePresale(
        signatureResponse.nonce,
        signatureResponse.signature,
        {
          value: price,
        }
      );
      const response = await tx.wait();
      console.log(response);
    } catch (error) {
      console.log(error);
      handleModal('There was an error validating your address. Please refresh and try again.');
    }
  };

  useEffect(() => {
    if (params.get('refcode')) {
      setRefcode(params.get('refcode'));
    }
    if (params.get('email')) {
      setEmail(params.get('email'));
    }
  }, [params]);

  useEffect(() => {
    if (localStorage.getItem(AUTHENTICATED_COOKIE_KEY_2)) {
      setAuthenticated(true);
      setAuth(true);
      cookies.set(AUTHENTICATED_COOKIE_KEY_2, localStorage.getItem(AUTHENTICATED_COOKIE_KEY_2), {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
      });
    }
    if (authToken.length > 0) {
      setAuth(true);
    }
  }, [authToken, authenticated]);

  useEffect(() => {
    console.log('library', library);
    console.log('user', user);
  }, [library]);

  return (
    <Layout>
      <SEO />
      {authenticated && auth ? (
        <HomeWrapper>
          <One />
          <Two />
          <Three />
          <Four />
          <Five />
          <Six />
          <Seven />
          <Eight />
        </HomeWrapper>
      ) : (
        <Wrapper>
          {typeof document !== `undefined` ? (
            <Modal fade={false} defaultOpened={false} ref={modal}>
              <ModalTextWrap>
                <h4>{modaltext}</h4>
              </ModalTextWrap>
            </Modal>
          ) : null}
          <Inner>
            <InnerImg src={brandHeroLogo} alt="logo" />
            {submitClicked && <H2>Welcome to the Party!!!</H2>}
            {!submitClicked ? (
              <Form>
                <FormInner>
                  <label></label>
                  <Input4
                    type="text"
                    id="refcode"
                    placeholder="EMAIL"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Input4>
                  <Input4
                    type="text"
                    id="refcode"
                    placeholder="PASSCODE"
                    value={refcode}
                    onChange={(e) => setRefcode(e.target.value)}
                  ></Input4>
                  <RefBtn
                    id="submit"
                    onClick={(e) => {
                      handleSetRefcode(e);
                      !ethCookie && handleModal('Please connect your wallet to continue!');
                    }}
                  >
                    ENTER
                  </RefBtn>
                </FormInner>
              </Form>
            ) : (
              <Inner>
                <FormDiv>
                  <FormInner>
                    <Msg style={{ textAlign: 'center', color: '#010101', marginBottom: '10px' }}>
                      {`connect your wallet to mint one Animal. Each Animal is ${MINT_PRICE}`}
                      <br />
                    </Msg>
                    <RefBtn
                      id="mint"
                      onClick={(e) => {
                        !alreadyMinted && handleMint(e);
                        alreadyMinted &&
                          handleModal(
                            "Only one token can be minted per wallet. Looks like you've already minted!"
                          );
                      }}
                    >
                      MINT
                    </RefBtn>
                  </FormInner>
                </FormDiv>
              </Inner>
            )}
          </Inner>
        </Wrapper>
      )}
    </Layout>
  );
};

const InnerImg = styled.img`
  height: 100%;
  width: 100%;
  min-width: 100%;
`;
const Msg = styled.div`
  margin-top: 30px;
  width: 80%;
  color: #fff;
`;
const HomeWrapper = styled.div`
  overflow: clip;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  background-image: url(${crumplebgDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  max-height: 100vh;
  overflow: clip;
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    background-image: url(${crumplebgMobile});
    background-position: top;
  }
`;
const Inner = styled.div`
  min-width: 400px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: -60px;
  }
  ${media.tablet} {
    img {
      margin-bottom: -20px;
    }
  }
`;
const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #060707;
  margin-bottom: 15%;
  width: 600px;
  ${media.tablet} {
    width: 300px;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #060707;
  margin-bottom: 15%;
  width: 600px;
  ${media.tablet} {
    width: 300px;
  }
`;
const FormInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #f2eae1;
  margin: 15px;
  padding: 65px 35px 65px 35px;
  width: 570px;
  ${media.tablet} {
    width: 270px;
  }
`;
const H2 = styled.h2`
  background: linear-gradient(100deg, #b29352 10%, #d9c9a9 50%, #b29352 90%);
  background-clip: text;
  text-fill-color: transparent;
  background: -webkit-linear-gradient(100deg, #b29352 10%, #d9c9a9 50%, #b29352 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
  margin-bottom: 20px;
`;
const Input4 = styled.input`
  color: #101010;
  width: 100%;
  font-size: 0.75rem;
  margin-top: 0rem;
  margin-bottom: 2.5rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0.5rem 0.5rem 0.5rem 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-radius: 0;
  background: transparent;
  outline: none;
`;
const RefBtn = styled.button`
  color: #101010;
  background: transparent;
  padding: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: block;
  height: 40px;
  width: 200px;
  border-radius: 50%;
  border: 2px solid #f93f06;
  font-size: 0.9rem;
  transition: all 250ms linear;
  :hover {
    color: #f93f06;
    transition: all 150ms linear;
  }
  :active {
    transform: translateY(2px);
    transition: all 150ms linear;
  }
  :visted {
    transform: translateY(2px);
    transition: all 150ms linear;
  }
`;
const ModalTextWrap = styled.div`
  height: 200px;
  width: 500px;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    width: 270px;
  }
`;

export const pageQuery = graphql`
  query {
    allCounts {
      nodes {
        gabe
        keith
        michael
        rossi
        nyc
        craniums
        alex
        playboy
        karsen
        z
        fox
        password
        matt
        nolimits
        house
        artbasel
        basel
        miami
        zhovak
        swans
        beer
        jpeg
        midnight
        nosleep
        back2back
        animalz
      }
    }
  }
`;

export default IndexPage;
